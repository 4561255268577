export const priceData = [
    [1722317409000, 200, 250, 150, 220],
    [1722403809000, 240, 260, 180, 200],
    [1722490209000, 210, 250, 190, 220],
    [1722576609000, 220, 270, 200, 230],
    [1722663009000, 230, 280, 210, 250],
    [1722749409000, 250, 300, 220, 240],
    [1722835809000, 240, 290, 210, 260],
    [1722922209000, 260, 310, 230, 270],
    [1723008609000, 270, 320, 240, 280],
    [1723095009000, 280, 330, 250, 290],
    [1723181409000, 290, 340, 260, 300],
    [1723267809000, 300, 350, 270, 310],
    [1723354209000, 310, 360, 280, 320],
    [1723440609000, 320, 370, 290, 330],
    [1723527009000, 330, 380, 300, 310],
    [1723613409000, 310, 350, 280, 300],
    [1723699809000, 300, 340, 270, 290],
    [1723786209000, 290, 330, 260, 280],
    [1723872609000, 280, 320, 250, 270],
    [1723959009000, 270, 310, 240, 260],
    [1724045409000, 260, 300, 230, 250],
    [1724131809000, 250, 290, 220, 240],
    [1724218209000, 240, 280, 210, 230],
    [1724304609000, 230, 270, 200, 220],
    [1724391009000, 220, 260, 190, 210],
    [1724477409000, 210, 250, 180, 200],
    [1724563809000, 200, 240, 170, 190],
    [1724650209000, 190, 230, 160, 180],
    [1724736609000, 180, 220, 150, 170],
    [1724823009000, 170, 210, 140, 160],
    [1724909409000, 180, 220, 160, 200],
    [1724995809000, 200, 240, 180, 220],
    [1725082209000, 220, 260, 200, 240],
    [1725168609000, 240, 280, 220, 260],
    [1725255009000, 260, 300, 240, 280],
    [1725341409000, 280, 320, 260, 300],
    [1725427809000, 300, 340, 280, 320],
    [1725514209000, 320, 360, 300, 340],
    [1725600609000, 340, 380, 320, 360],
    [1725687009000, 360, 400, 340, 380],
    [1725773409000, 380, 420, 360, 400],
    [1725859809000, 400, 440, 380, 420],
    [1725946209000, 420, 460, 400, 440],
    [1726032609000, 440, 480, 420, 460],
    [1726119009000, 460, 500, 440, 480],
    [1726205409000, 480, 520, 460, 500],
    [1726291809000, 500, 540, 480, 520],
    [1726378209000, 520, 560, 500, 540],
    [1726464609000, 540, 580, 520, 560],
    [1726551009000, 560, 600, 540, 580]
  ];
  
export const volumeData = [
    [1722317409000, 230],
    [1722403809000, 50],
    [1722490209000, 150],
    [1722576609000, 50],
    [1722663009000, 600],
    [1722749409000, 190],
    [1722835809000, 50],
    [1722922209000, 100],
    [1723008609000, 80],
    [1723095009000, 500],
    [1723181409000, 230],
    [1723267809000, 50],
    [1723354209000, 150],
    [1723440609000, 50],
    [1723527009000, 600],
    [1723613409000, 190],
    [1723699809000, 50],
    [1723786209000, 100],
    [1723872609000, 80],
    [1723959009000, 500],
    [1724045409000, 230],
    [1724131809000, 50],
    [1724218209000, 150],
    [1724304609000, 50],
    [1724391009000, 600],
    [1724477409000, 190],
    [1724563809000, 50],
    [1724650209000, 100],
    [1724736609000, 80],
    [1724823009000, 500],
    [1724909409000, 230],
    [1724995809000, 50],
    [1725082209000, 150],
    [1725168609000, 50],
    [1725255009000, 600],
    [1725341409000, 190],
    [1725427809000, 50],
    [1725514209000, 100],
    [1725600609000, 80],
    [1725687009000, 500],
    [1725773409000, 230],
    [1725859809000, 50],
    [1725946209000, 150],
    [1726032609000, 50],
    [1726119009000, 600],
    [1726205409000, 190],
    [1726291809000, 50],
    [1726378209000, 100],
    [1726464609000, 80],
    [1726551009000, 500]
  ];