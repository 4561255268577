import Highcharts from 'highcharts/highstock';

let chartInstance = null;

export const setChartInstance = (instance) => {
  chartInstance = instance;
};

export const updateChartColors = (theme) => {
  if (chartInstance) {

    const backgroundColor = `var(--chart-bg)`;
    const textColor = `var(--chart-text)`;

    const upColor = `var(--chart-up)`;
    const downColor = `var(--chart-down)`;
    const volumeColor = `var(--chart-volume)`;

    chartInstance.update({
      chart: {
        backgroundColor: backgroundColor,
      },
      xAxis: {
        labels: {
          style: {
            color: textColor,
          },
        }
      },
      yAxis: [
        {
          labels: {
            style: {
              color: textColor,
            },
            formatter: function () {
              return Highcharts.numberFormat(this.value, 0, '.', ',');
            }
          },
        },
        {
          labels: {
            style: {
              color: textColor,
            },
          },
        },
      ],
      series: [
        {
          id: 'price',
          color: downColor,
          lineColor: downColor,
          upColor: upColor,
          upLineColor: upColor,
        },
        {
          id: 'volume',
          color: volumeColor
        }
      ],
      rangeSelector: {
        buttonTheme: {
          fill: backgroundColor,
          style: {
            color: textColor
          }
        },
        inputBoxBorderColor: backgroundColor,
        inputStyle: {
          backgroundColor: backgroundColor,
          color: textColor,
        },
        labelStyle: {
          color: textColor
        },
      },
    }, false); // `false` para evitar un redibujo completo

    chartInstance.redraw(); // Redibujar el gráfico para aplicar los cambios
  }
};

let selectedIndex = 'I.RFX20';

 
export const setSelectedIndex = (value) => {
  selectedIndex = value;
}

export const getSelectedIndex = () => {
  return selectedIndex;
}


let showTables = true;

export const toggleTablesVisibility = () => {
  showTables = !showTables;
};

export const getShowTables = () => {
    return showTables;
};
