import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { setChartInstance } from '../../utils/chartUtils';
import './chart.css';

import { priceData, volumeData } from './chartData';

import StockTools from 'highcharts/modules/stock-tools';
import Annotations from 'highcharts/modules/annotations-advanced';
import Boost from 'highcharts/modules/boost';
import PriceIndicator from 'highcharts/modules/price-indicator';
import Indicators from 'highcharts/indicators/indicators-all';
import FullScreen from 'highcharts/modules/full-screen';
import Accessibility from 'highcharts/modules/accessibility';
import HighchartsMore from 'highcharts/highcharts-more';
import HeikinAshi from 'highcharts/modules/heikinashi';
import HollowCandleStick from 'highcharts/modules/hollowcandlestick';

Indicators(Highcharts);
Annotations(Highcharts);
PriceIndicator(Highcharts);
StockTools(Highcharts);
FullScreen(Highcharts);
Boost(Highcharts);
Accessibility(Highcharts);
HighchartsMore(Highcharts);
HeikinAshi(Highcharts);
HollowCandleStick(Highcharts);

Highcharts.setOptions({
  time: {
    timezoneOffset: 300 // Argentina is UTC-3
  }
});

const ChartBody = () => {
  const chartRef = useRef(null);

  const chartOptions = {
    chart: {
      backgroundColor: 'var(--chart-bg)', // Inicializa con la variable genérica
    },
    xAxis: {
      labels: {
        style: {
          color: 'var(--chart-text)',
        },
      }
    },
    yAxis: {
      labels: {
        align: 'right',
        x: -5,  // Ajusta el offset horizontal si es necesario
        style: {
          color: 'var(--chart-text)',
        },
        formatter: function () {
          return Highcharts.numberFormat(this.value, 0, '.', ',');
        }
      },
      offset: 50,  // Aumenta el espacio a la derecha del gráfico
      height: '100%',  // Ocupa el 100% del alto del gráfico para ambos (precio y volumen)
    },
    series: [
      {
        type: 'candlestick',
        id: 'price',
        name: 'Price',
        data: priceData,
        color: 'var(--chart-down)',
        lineColor: 'var(--chart-down)',
        upColor: 'var(--chart-up)',
        upLineColor: 'var(--chart-up)',
        yAxis: 0,  // Vincula esta serie con el eje Y principal
        zIndex: 2,  // Asegura que la serie de precios esté por encima del volumen
      },
      {
        type: 'column',
        id: 'volume',
        name: 'Volume',
        data: volumeData.map(([x, y]) => [x, y * 0.2]),  // Escala el volumen al 20% de su valor
        color: 'var(--chart-volume)',
        borderWidth: 0,  // Elimina las líneas alrededor de las columnas
        yAxis: 0,  // Usa el mismo eje Y que la serie de precios
        zIndex: 1,  // Asegura que el volumen esté debajo de las velas
      }
    ],
    tooltip: {
      positioner: function () {
        return { x: 100, y: 100 };  // Posiciona el tooltip en la esquina superior izquierda
      },
      shared: true,
      split: false,
    },
    stockTools: {
      gui: {
        enabled: true
      },
    },
    navigator: {
      enabled: false
    },
    rangeSelector: {
      buttons: [
        { count: 1, type: 'day', text: '1d' },
        { count: 7, type: 'day', text: '7d' },
        { count: 1, type: 'month', text: '1m' },
        { type: 'all', text: 'All' },
      ],
      buttonTheme: {
        fill: 'var(--buttons-bg)',
        style: {
          color: 'var(--buttons-text)',
        }
      },
      inputBoxBorderColor: 'var(--buttons-text)',
      inputStyle: {
        color: 'var(--chart-volume)',
      },
      labelStyle: {
        color: 'var(--chart-volume)'
      }
    },
  };  

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;
      setChartInstance(chart);
    }
  }, []);

  return (
    <div className="chart-body">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={'stockChart'}
        options={chartOptions}
        ref={chartRef}
        containerProps={{ style: { height: '100%' } }}
      />
    </div>
  );
};

export default React.memo(ChartBody);