import React, { createContext, useState, useContext } from 'react';

const UIContext = createContext();

export const UIProvider = ({ children }) => {
  const [showTables, setShowTables] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showSelectFutureModal, setShowSelectFutureModal] = useState(false);
  const [showCompareFuturesModal, setShowCompareFuturesModal] = useState(false);
  const [showSavedChartsModal, setShowSavedChartsModal] = useState(false);

  const toggleTablesVisibility = () => {
    setShowTables(prevShowTables => !prevShowTables);
  };

  const handleShowModal = (modalName) => {
    if (modalName === 'SelectFutureModal') {
      setShowSelectFutureModal(true);
    } else if (modalName === 'CompareFuturesModal') {
      setShowCompareFuturesModal(true);
    } else if (modalName === 'SavedChartsModal') {
      setShowSavedChartsModal(true);
    }
  };

  const handleCloseModal = (modalName) => {
    if (modalName === 'SelectFutureModal') {
      setShowSelectFutureModal(false);
    } else if (modalName === 'CompareFuturesModal') {
      setShowCompareFuturesModal(false);
    } else if (modalName === 'SavedChartsModal') {
      setShowSavedChartsModal(false);
    }
  };

  return (
    <UIContext.Provider value={{
      showTables, toggleTablesVisibility, loading, setLoading,
      showSelectFutureModal, showCompareFuturesModal, showSavedChartsModal,
      handleShowModal, handleCloseModal
    }}>
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => useContext(UIContext);