import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useData } from '../context/DataContext';

const CompareFuturesModal = ({ show, handleClose, onSelect }) => {
  const { futures } = useData(); // Obtiene los futuros desde el contexto
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFutures, setSelectedFutures] = useState([]);

  const handleSelect = (future) => {
    if (!selectedFutures.includes(future)) {
      setSelectedFutures([...selectedFutures, future]);
    }
  };

  const handleCompare = () => {
    onSelect(selectedFutures);
    handleClose();
  };

  const filteredFutures = futures.filter(future => future.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Comparar Futuros</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Buscar futuro"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {Array.isArray(filteredFutures) && filteredFutures.map((future, index) => (
          <div key={index} onClick={() => handleSelect(future)} style={{ cursor: 'pointer', padding: '5px' }}>
            {future}
          </div>
        ))}
        <div>
          <h5>Futuros Seleccionados:</h5>
          {selectedFutures.map((future, index) => (
            <div key={index}>{future}</div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
        <Button variant="primary" onClick={handleCompare}>Comparar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompareFuturesModal;