import React, { createContext, useContext } from 'react';
import useWebSocket from '../../hooks/useWebSocket';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ token, symbol, setHistoricalData, setTodayData, setFutureData, setIndexData, setLoading, children }) => {
  const { wsConnected } = useWebSocket(token, symbol, setHistoricalData, setTodayData, setFutureData, setIndexData, setLoading);

  return (
    <WebSocketContext.Provider value={{ wsConnected }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => useContext(WebSocketContext);