import React from 'react';
import { Table } from 'react-bootstrap';

const TablaFuturos = ({ data, getValue }) => {
  
  const marketData = data.length > 0 ? data[0].marketData : {};

  return (
    <>
      <Table>
        <tbody>
          <tr>
            <td>Timestamp</td>
            <td>{data.length > 0 ? new Date(data[0].timestamp).toLocaleString() : ''}</td>
          </tr>
          <tr>
            <td>Open Interest</td>
            <td>{getValue(marketData.OI)}</td>
          </tr>
          <tr>
            <td>Settlement Price</td>
            <td>{getValue(marketData.SE?.price)}</td>
          </tr>
          <tr>
            <td>Settlement Date</td>
            <td>{getValue(marketData.SE ? new Date(marketData.SE.date).toLocaleDateString() : '')}</td>
          </tr>
          <tr>
            <td>Last Trade Price</td>
            <td>{getValue(marketData.LA)}</td>
          </tr>
          <tr>
            <td>Trade Volume</td>
            <td>{getValue(marketData.TV)}</td>
          </tr>
          <tr>
            <td>Nominal Volume</td>
            <td>{getValue(marketData.NV)}</td>
          </tr>
          <tr>
            <td>Closing Price</td>
            <td>{getValue(marketData.CL?.price)}</td>
          </tr>
          <tr>
            <td>Closing Date</td>
            <td>{getValue(marketData.CL ? new Date(marketData.CL.date).toLocaleDateString() : '')}</td>
          </tr>
          <tr>
            <td>Indicative Volume</td>
            <td>{getValue(marketData.IV)}</td>
          </tr>
          <tr>
            <td>Exchange Volume</td>
            <td>{getValue(marketData.EV)}</td>
          </tr>
        </tbody>
      </Table>

      <Table className="table-with-scroll">
        <thead>
          <tr>
            <th className="text-center">Compra</th>
            <th className="text-center">Venta</th>
          </tr>
        </thead>
        <tbody className="scrollable-tbody">
          <tr>
            <td>
              <Table >
                <thead>
                  <tr>
                    <th className="text-center">Precio</th>
                    <th className="text-center">Tamaño</th>
                  </tr>
                </thead>
                <tbody>
                  {marketData.BI && marketData.BI.length > 0 &&
                    marketData.BI.map((item, index) => (
                      <tr key={index}>
                        <td>{getValue(item.price)}</td>
                        <td>{getValue(item.size)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </td>
            <td>
              <Table >
                <thead>
                  <tr>
                    <th className="text-center">Precio</th>
                    <th className="text-center">Tamaño</th>
                  </tr>
                </thead>
                <tbody>
                  {marketData.OF && marketData.OF.length > 0 &&
                    marketData.OF.map((item, index) => (
                      <tr key={index}>
                        <td>{getValue(item.price)}</td>
                        <td>{getValue(item.size)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default TablaFuturos;