import React from 'react';
import TablaIndices from './TablaIndices';
import TablaFuturos from './TablaFuturos';
import TablaTodayTrades from './TablaTodayTrades';
import { getValue } from '../../utils/utils';
import { useData } from '../context/DataContext';
import { useTheme } from '../context/ThemeContext';
import { getSelectedIndex, setSelectedIndex } from '../../utils/chartUtils.js';
import './tablas.css';

const Tablas = ({ showTables }) => {
  const { indexData, futureData, todayData } = useData();
  const { theme } = useTheme();

  return (
    <div className={`tables-container ${!showTables ? 'hidden' : ''} ${theme}`}>
      <TablaIndices 
        data={Array.isArray(indexData) ? indexData.filter(item => item.name === getSelectedIndex()) : []} 
        getValue={getValue} 
        selectedIndex={getSelectedIndex()} 
        setSelectedIndex={setSelectedIndex}
      />
      <TablaFuturos 
        data={Array.isArray(futureData) ? futureData : []} 
        getValue={getValue} 
      />
      <TablaTodayTrades 
        data={Array.isArray(todayData) ? todayData : []} 
      />
    </div>
  );
};

export default Tablas;