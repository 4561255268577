import React from 'react';
import { useData } from '../context/DataContext';
import { useUI } from '../context/UIContext';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import SelectFutureModal from '../modals/SelectFutureModal';
import CompareFuturesModal from '../modals/CompareFuturesModal';
import SavedChartsModal from '../modals/SavedChartsModal';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

const ChartHeader = () => {
  const { setInterval, indice, setIndice, intervalLabel, setIntervalLabel } = useData();
  const { showTables, toggleTablesVisibility, showSelectFutureModal, showCompareFuturesModal, showSavedChartsModal, handleShowModal, handleCloseModal } = useUI();

  const handleIntervalChange = (interval, label) => {
    setInterval(interval);
    setIntervalLabel(label);
  };

  return (
    <div className="chart-header">
      <Button variant="success" onClick={() => handleShowModal('SelectFutureModal')}>Seleccionar Futuro</Button>
      <Button variant="success" onClick={() => handleShowModal('CompareFuturesModal')}>Comparar Futuros</Button>
      <Button variant="success" onClick={() => handleShowModal('SavedChartsModal')}>Seleccionar Gráfico</Button>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {intervalLabel}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleIntervalChange(300000, '5 minutos')}>5 minutos</Dropdown.Item>
          <Dropdown.Item onClick={() => handleIntervalChange(600000, '10 minutos')}>10 minutos</Dropdown.Item>
          <Dropdown.Item onClick={() => handleIntervalChange(1800000, '30 minutos')}>30 minutos</Dropdown.Item>
          <Dropdown.Item onClick={() => handleIntervalChange(3600000, '1 hora')}>1 hora</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          {indice}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setIndice('I.BTC')}>I.BTC</Dropdown.Item>
          <Dropdown.Item onClick={() => setIndice('I.SOJA')}>I.SOJA</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Button variant="secondary" onClick={toggleTablesVisibility}
      title={showTables ? 'Ocultar tablas' : 'Mostrar tablas'}>
        {showTables ? <ChevronRight /> : <ChevronLeft />}
      </Button>

      {/* Modals */}
      <SelectFutureModal
        show={showSelectFutureModal}
        handleClose={() => handleCloseModal('SelectFutureModal')}
      />
      <CompareFuturesModal
        show={showCompareFuturesModal}
        handleClose={() => handleCloseModal('CompareFuturesModal')}
      />
      <SavedChartsModal
        show={showSavedChartsModal}
        handleClose={() => handleCloseModal('SavedChartsModal')}
      />
    </div>
  );
};

export default React.memo(ChartHeader);