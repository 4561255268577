import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.clear();
    setToken(null);
    setUser(null);
    setAlertMessage('');
    navigate('/login');
  }, [navigate]);

  const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const exp = decodedToken.exp * 1000;
    const now = Date.now();
    return now >= exp;
  };

  const checkTokenExpiration = useCallback(() => {
    if (!token || isTokenExpired(token)) {
      setAlertMessage('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.');
      setTimeout(handleLogout, 5000);
      return false;
    }
    return true;
  }, [token, handleLogout]);

  useEffect(() => {
    const interval = setInterval(checkTokenExpiration, 5 * 60 * 1000); // Cada 5 minutos
    return () => clearInterval(interval);
  }, [checkTokenExpiration]);

  useEffect(() => {
    if (!token) {
      handleLogout(); // Asegurar que se realice el logout si no hay token
    }
  }, [token, handleLogout]);

  const loginUser = async (email, password) => {
    try {
      const response = await axios.post('https://bee-connections.com/api/login', { email, password });
      setToken(response.data.token);
      setUser({ nombre: response.data.nombre, apellido: response.data.apellido, avatar: response.data.avatar, email: response.data.email });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify({ nombre: response.data.nombre, apellido: response.data.apellido, avatar: response.data.avatar, email: response.data.email }));
      navigate('/'); // Redirigir a la página principal después del login
    } catch (error) {
      console.error('Error en la autenticación:', error);
      setAlertMessage(error.response?.data?.message || 'Error en la autenticación');
    }
  };

  const registerUser = async (nombre, apellido, email, password, avatar) => {
    try {
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('nombre', nombre);
      formData.append('apellido', apellido);
      if (avatar) {
        formData.append('avatar', avatar);
      }
      await axios.post('https://bee-connections.com/api/signup', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlertMessage('Usuario registrado correctamente. Ahora puede iniciar sesión.');
    } catch (error) {
      console.error('Error en el registro:', error);
      setAlertMessage(error.response?.data?.message || 'Error en el registro');
    }
  };

  const updateUser = async (nombre, apellido, email, avatar) => {
    try {
      const formData = new FormData();
      formData.append('nombre', nombre);
      formData.append('apellido', apellido);
      formData.append('email', email);

      if (avatar) {
        formData.append('avatar', avatar);
      }
  
      const response = await axios.put('https://bee-connections.com/api/updateUser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
      });

      console.log('Usuario actualizado:', response);
      
      if (response.status === 200 && response.data) {
        // Actualizamos el usuario en el contexto
        const updatedUser = {
          nombre: response.data.data.nombre,
          apellido: response.data.data.apellido,
          email: response.data.data.email,
          avatar: response.data.data.avatar
        };
        console.log('Usuario actualizado en el contexto:', updatedUser);
        
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setAlertMessage('Usuario actualizado correctamente');
      }
    } catch (error) {
      console.error('Error al actualizar el usuario:', error);
      setAlertMessage(error.response?.data?.message || 'Error al actualizar el usuario');
    }
  };
  
  return (
    <AuthContext.Provider value={{
      token, user, alertMessage, setAlertMessage, handleLogout, setToken, setUser, loginUser, registerUser, updateUser
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);