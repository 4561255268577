import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const LogoutConfirmModal = ({ show, handleClose, handleLogout }) => {

  const confirmLogout = () => {
    handleClose();
    handleLogout();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Cierre de Sesión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro que deseas cerrar sesión?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={confirmLogout}>
          Cerrar Sesión
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LogoutConfirmModal;