import React, { useEffect } from 'react';
import './App.css';
import ChartHeader from './components/chart/ChartHeader';
import ChartBody from './components/chart/ChartBody';
import Tablas from './components/tables/Tablas';
import Login from './components/auth/Login';
import Navbar from './components/layout/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import { DataProvider, useData } from './components/context/DataContext';
import { UIProvider, useUI } from './components/context/UIContext';
import { WebSocketProvider } from './components/context/WebSocketContext';
import { ThemeProvider, useTheme } from './components/context/ThemeContext';

function App() {
  return (
    <Router>
      <ThemeProvider>
        <AuthProvider>
          <DataProvider>
            <UIProvider>
              <AppContent />
            </UIProvider>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}

function AppContent() {
  const { token, user, alertMessage, setAlertMessage, handleLogout } = useAuth();
  const { symbol, setHistoricalData, setTodayData, setFutureData, setIndexData, setLoading } = useData();
  const { showTables } = useUI();  // Asegúrate de que viene del UIProvider
  const { theme } = useTheme();

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <WebSocketProvider
      token={token}
      symbol={symbol}
      setHistoricalData={setHistoricalData}
      setTodayData={setTodayData}
      setFutureData={setFutureData}
      setIndexData={setIndexData}
      setLoading={setLoading}
    >
      <div className={`App`}>
        {alertMessage && (
          <Alert variant="warning" onClose={() => setAlertMessage('')} dismissible>
            {alertMessage}
          </Alert>
        )}
        {token ? (
          <>
            <Navbar user={user} handleLogout={handleLogout} />
            <div className="main-container">
              <div className={`chart-container ${!showTables ? 'full-width' : ''}`}>
                <ChartHeader />
                <ChartBody />
              </div>
              <Tablas showTables={showTables} />
            </div>
            <Routes>
              <Route path="*" element={<Navigate to="/futuros" />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        )}
      </div>
    </WebSocketProvider>
  );
}

export default App;