import React, { createContext, useState, useContext } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [symbol, setSymbol] = useState('RFX20/AGO24');
  const [historicalData, setHistoricalData] = useState([]);
  const [todayData, setTodayData] = useState([]);
  const [indexData, setIndexData] = useState([]);
  const [indice, setIndice] = useState('I.BTC');
  const [futureData, setFutureData] = useState([]);
  const [futures, setFutures] = useState([]);
  
  const [interval, setInterval] = useState(300000);  // Intervalo predeterminado: 5 minutos
  const [intervalLabel, setIntervalLabel] = useState('5 minutos');  // Etiqueta predeterminada

  return (
    <DataContext.Provider value={{
      symbol, setSymbol, historicalData, setHistoricalData,
      todayData, setTodayData, indexData, setIndexData,
      futureData, setFutureData, futures, setFutures,
      interval, setInterval, intervalLabel, setIntervalLabel,
      indice, setIndice
    }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);