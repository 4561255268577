import React, { useState, useCallback } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useData } from '../context/DataContext';

const SelectFutureModal = ({ show, handleClose }) => {
  const { setLoading, setTodayData, setHistoricalData, setSymbol, futures } = useData();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelect = useCallback((future) => {
    setLoading(true);
    setHistoricalData([]);
    setTodayData([]);
    setSymbol(future);
    handleClose();
  }, [setLoading, setHistoricalData, setTodayData, setSymbol, handleClose]);

  const filteredFutures = futures.filter(future => future.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Seleccionar Futuro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Buscar futuro"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {Array.isArray(filteredFutures) && filteredFutures.map((future, index) => (
          <div key={index} onClick={() => handleSelect(future)} style={{ cursor: 'pointer', padding: '5px' }}>
            {future}
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectFutureModal;