import React, { useState } from 'react';
import './navbar.css'; // Importa el archivo CSS del Navbar
import { useTheme } from '../context/ThemeContext';
import { Sun, Moon } from 'react-bootstrap-icons';
import UserProfileModal from '../modals/UserProfileModal.jsx';
import LogoutConfirmModal from '../modals/LogoutConfirmModal.jsx';
import { Dropdown } from 'react-bootstrap';

const defaultAvatar = 'https://www.gravatar.com/avatar?d=mp&s=200'; // URL de un avatar por defecto

const Navbar = ({ user, handleLogout }) => {
  const { theme, toggleTheme } = useTheme();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // Estado para controlar el dropdown

  const handleProfileClick = () => {
    setShowProfileModal(true);
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg`}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="navbar-brand" style={{ fontSize: '1.5rem', fontFamily: 'Arial, sans-serif' }}>
            <span style={{ fontWeight: 'bold', color: '#FFC107' }}>Bee</span>
            <span style={{ color: theme === 'dark' ? '#ffffff' : '#000000' }}>Trading</span>
          </div>
          <div className="theme-toggle-container">
            <input 
              type="checkbox" 
              id="theme-toggle" 
              className="theme-toggle" 
              checked={theme === 'dark'} 
              onChange={toggleTheme} 
            />
            <label className="theme-toggle-label" htmlFor="theme-toggle">
              <div className="theme-icon-container">
                {theme === 'dark' ? <Sun className="theme-icon" /> : <Moon className="theme-icon" />}
              </div>
            </label>
          </div>

          <div className="d-flex align-items-center">
            <span className="user-name">
              {user.nombre} {user.apellido}
            </span>

            <Dropdown show={showDropdown} onToggle={toggleDropdown} align="end">
              <Dropdown.Toggle
                variant="link"
                className="user-info p-0"
                onClick={toggleDropdown}
              >
                <img
                  src={user.avatar ? `https://bee-connections.com/${user.avatar}` : defaultAvatar}
                  alt="User Avatar"
                  className="navbar-avatar"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleProfileClick}>Perfil</Dropdown.Item>
                <Dropdown.Item onClick={handleLogoutClick}>Cerrar Sesión</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </nav>

      {/* Modal de perfil de usuario */}
      <UserProfileModal show={showProfileModal} handleClose={() => setShowProfileModal(false)} user={user} />

      {/* Modal de confirmación de cierre de sesión */}
      <LogoutConfirmModal show={showLogoutConfirm} handleClose={() => setShowLogoutConfirm(false)} handleLogout={handleLogout} />
    </>
  );
};

export default Navbar;