import React from 'react';
import { Table } from 'react-bootstrap';

const TablaTodayTrades = ({ data }) => {
  const adjustedData = data.map(trade => ({
    ...trade,
    datetime: new Date(new Date(trade.datetime).getTime() - 3 * 60 * 60 * 1000) // Restar 3 horas
  })).reverse(); // Invertir los datos para mostrar el más reciente primero

  return (
    <Table className="table-with-scroll">
      <thead>
        <tr>
          <th className="text-center">Hora</th>
          <th className="text-center">Precio</th>
          <th className="text-center">Volumen</th>
        </tr>
      </thead>
      <tbody className="scrollable-tbody">
        {adjustedData.length === 0 ? (
          <tr>
            <td colSpan="3" className="text-center">
              <div className="no-data-message">No se realizaron operaciones aún.</div>
            </td>
          </tr>
        ) : (
          adjustedData.map((trade, index) => (
            <tr key={index}>
              <td>{trade.datetime.toLocaleTimeString()}</td>
              <td>{trade.price}</td>
              <td>{trade.size}</td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default TablaTodayTrades;