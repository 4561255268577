import React from 'react';
import { Table } from 'react-bootstrap';

const TablaIndices = ({ data, getValue }) => {
  const index = data.length > 0 ? data[0] : {};

  return (
    <Table>
      <tbody>
        <tr>
          <th>Nombre</th>
          <td>{getValue(index.name)}</td>
        </tr>
        <tr>
          <th>Valor del Índice</th>
          <td>{getValue(index.indexValue, true)}</td>
        </tr>
        <tr>
          <th>Valor Nominal</th>
          <td>{getValue(index.nominalValue, true)}</td>
        </tr>
        <tr>
          <th>Costo del Comercio</th>
          <td>{getValue(index.costOfTrade, true)}</td>
        </tr>
        <tr>
          <th>Fecha y Hora de Entrada</th>
          <td>{index.mdEntryDateTime || '-'}</td>
        </tr>
        <tr>
          <th>Valor de Cierre del Índice</th>
          <td>{getValue(index.closeIndexValue, true)}</td>
        </tr>
        <tr>
          <th>Fecha de Cierre del Índice</th>
          <td>{index.closeIndexDate || '-'}</td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TablaIndices;