export const formatPrice = (price) => {
  if (price == null) return '-';
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  }).format(price);
};

export const formatDate = (timestamp) => {
  if (!timestamp) return '-';
  const date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const getValue = (value, isCurrency = false) => {
  if (value == null) return '-';
  return isCurrency ? formatPrice(value) : value.toString();
};

export const processTrades = (trades, interval) => {
  const groupedTrades = {};

  trades.forEach((trade) => {
    const time = new Date(trade.datetime).getTime();
    const roundedTime = Math.floor(time / interval) * interval;

    if (!groupedTrades[roundedTime]) {
      groupedTrades[roundedTime] = {
        open: trade.price,
        high: trade.price,
        low: trade.price,
        close: trade.price,
        volume: trade.size,
        date: new Date(roundedTime).getTime(), // Ensure the date is in the correct format
      };
    } else {
      groupedTrades[roundedTime].high = Math.max(groupedTrades[roundedTime].high, trade.price);
      groupedTrades[roundedTime].low = Math.min(groupedTrades[roundedTime].low, trade.price);
      groupedTrades[roundedTime].close = trade.price;
      groupedTrades[roundedTime].volume += trade.size;
    }
  });

  return Object.values(groupedTrades).sort((a, b) => a.date - b.date);
};

export const filterTodayTrades = (trades) => {
  const today = new Date();
  const startOfDay = new Date(today.setHours(0, 0, 0, 0)).getTime();
  return trades.filter(trade => new Date(trade.datetime).getTime() >= startOfDay);
};